import React, {
  ChangeEvent,
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { MenuItem } from '@mui/material';
import {
  WritebackProfiler,
  OSLog,
  OperationType,
  OperationResult,
  OSWriteback,
  WritebackVersion,
} from '@getvim/vim-connect-app';
import { SelectChangeEvent } from '@mui/material/Select';
import { WritebackResponse } from '@getvim-adapter/api';
import {
  sendWritebackData,
  sendWritebackV2Data,
  sendUIInjectionData,
  createLog,
  createAutoTestLog,
  updateWritebackLog,
  removeToString,
  validateNumber,
  getWritebackResult,
} from '../../../services';
import { TextCount } from './TextCount';
import { ButtonIcon, ButtonLoader, JsonEditor, JsonViewer, Selector } from '../common';
import {
  getWritebackPayload,
  getUIInjectionPayload,
  EXPECTED_WRITEBACK_RESULTS_EXTENDED_OBJECT,
  USE_CASES_OPTIONS,
  isICDCode,
  generateUseCase,
} from '../../mocks/events';
import {
  LOG_TO_SHORT,
  OPERATIONS_MAP,
  OPERATION_TYPE_TO_SHORT,
  ICONS_MAP,
  EventType,
  LoaderSide,
  HiddenJsonType,
  UpdateEncounterType,
  UpdateReferralType,
} from '../../../types';
import { WritebackWarning } from '../warnings/WritebackWarning';

const TIME_OUT_BETWEEN_WRITEBACK: number = 10_000;

const writebackAssessmentsOptions: [string, string][] = Object.entries(
  EXPECTED_WRITEBACK_RESULTS_EXTENDED_OBJECT,
);
writebackAssessmentsOptions.unshift(['-1', 'Select Scenario']);

const useCasesOptions: [string, string][] = Object.entries(USE_CASES_OPTIONS);
useCasesOptions.unshift(['-1', 'Select Use Case']);

export const Operation = forwardRef<any, any>(
  (
    {
      id,
      eventName,
      eventType,
      writebacksData,
      localLogs,
      writebacks,
      uiInjections,
      onLogsUpdate,
      onWritebackDataChange,
    },
    ref,
  ) => {
    const targetRef = useRef<HTMLInputElement>(null);
    const [operationType, setOperationType] = useState<string>('');
    const writebackResultRef = useRef<HTMLParagraphElement | null>(null);
    const [operationId, setOperationId] = useState<string>('');
    const [payload, setPayload] = useState<string>();
    const [payloadConfig, setPayloadConfig] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [predefinedPayload, setPredefinedPayload] = useState<string>('');
    const [openSelectPayload, setOpenSelectPayload] = useState<boolean>(false);
    /**
     * @deprecated - Writeback Response v2
     * ToDo: Will be removed soon. Ticket: https://bookmd.atlassian.net/browse/SAM-60070
     * Please use setWritebackProfilerV2.
     */
    const [writebackProfilerV1, setWritebackProfilerV1] = useState<WritebackProfiler>(
      new WritebackProfiler(),
    );
    const [writebackProfilerV2, setWritebackProfilerV2] = useState<WritebackProfiler>(
      new WritebackProfiler(),
    );
    const [expectedWritebackResult, setExpectedWritebackResult] = useState<string>('v');
    const [useCase, setUseCase] = useState<string>('-1');
    const [runWritebackCount, setRunWritebackCount] = useState<number>(1);
    const [totalRunWritebackCount, setTotalRunWritebackCount] = useState<number>(1);
    const [isWritebackDataUpdated, setIsWritebackDataUpdated] = useState<boolean>(false);

    const setWritebackDataFromStorage = useCallback(
      (writebacksData: any): void => {
        try {
          let writebackData: OSWriteback | undefined = undefined;
          for (const key of writebacks) {
            if (key in writebacksData) {
              writebackData = writebacksData[key];
              break;
            }
          }
          if (writebackData) {
            if (writebackData.operationId) {
              setOperationId(writebackData.operationId);
            }
            if (writebackData.operationType) {
              setOperationType(writebackData.operationType);
            }
            if (writebackData.predefinedPayload) {
              setPredefinedPayload(writebackData.predefinedPayload);
            }
            if (writebackData.payload) {
              setPayload(removeToString(writebackData.payload));
            }
            if (writebackData.payloadConfig) {
              setPayloadConfig(removeToString(writebackData.payloadConfig));
            }
          }
        } catch (error) {
          console.error('Error setting writeback data from storage', error);
        }
      },
      [writebacks],
    );

    useEffect(() => {
      if (writebacksData && Object.keys(writebacksData).length > 0 && !isWritebackDataUpdated) {
        setWritebackDataFromStorage(writebacksData);
        setIsWritebackDataUpdated(true);
      }
    }, [writebacksData, isWritebackDataUpdated, setWritebackDataFromStorage]);

    useImperativeHandle(ref, () => ({
      operationResponse: (status: any) => {
        setIsLoading(false);
        if (writebackProfilerV1.autoTest) {
          updateResultV1(status);
        }
        const localWritebackProfiler: WritebackProfiler = {
          ...writebackProfilerV1,
          endTime: Date.now(),
          status,
        };
        setWritebackProfilerV1(localWritebackProfiler);
        updateLogs(localWritebackProfiler);
        if (!writebackProfilerV1.autoTest) {
          return;
        }
        if (runWritebackCount > 1) {
          setTimeout(() => {
            const localWritebackProfiler: WritebackProfiler = {
              ...writebackProfilerV1,
              startTime: Date.now(),
              endTime: null,
              status: null,
            };
            setWritebackProfilerV1(localWritebackProfiler);
            runWriteback(localWritebackProfiler, WritebackVersion.V1);
          }, TIME_OUT_BETWEEN_WRITEBACK);
          setRunWritebackCount(runWritebackCount - 1);
        } else {
          setRunWritebackCount(1);
        }
        if (writebackProfilerV1.autoTest!.count === totalRunWritebackCount) {
          setTotalRunWritebackCount(0);
          updateLogsAutoTest(writebackProfilerV1);
        }
      },
      operationResponseV2: (writebackResponse: WritebackResponse) => {
        setIsLoading(false);
        if (writebackProfilerV2.autoTest) {
          updateResultV2(writebackResponse);
        }
        const localWritebackProfiler: WritebackProfiler = {
          ...writebackProfilerV2,
          endTime: Date.now(),
          writebackResponse,
        };
        setWritebackProfilerV2(localWritebackProfiler);
        updateLogs(localWritebackProfiler);
        handleWritebackResult();
        if (!writebackProfilerV2.autoTest) {
          return;
        }
        if (runWritebackCount > 1) {
          setTimeout(() => {
            const localWritebackProfiler: WritebackProfiler = {
              ...writebackProfilerV2,
              startTime: Date.now(),
              endTime: null,
              writebackResponse: null,
            };
            setWritebackProfilerV2(localWritebackProfiler);
            runWriteback(localWritebackProfiler, WritebackVersion.V2);
          }, TIME_OUT_BETWEEN_WRITEBACK);
          setRunWritebackCount(runWritebackCount - 1);
        } else {
          setRunWritebackCount(1);
        }
        if (writebackProfilerV2.autoTest!.count === totalRunWritebackCount) {
          setTotalRunWritebackCount(0);
          updateLogsAutoTest(writebackProfilerV2);
        }
      },
    }));

    const updateLogs = (localWritebackProfiler: WritebackProfiler): void => {
      if (localWritebackProfiler) {
        const updatedLogs: OSLog[] = [
          updateWritebackLog(
            localLogs,
            id,
            localWritebackProfiler.endTime!,
            localWritebackProfiler.status!,
          ),
        ];
        onLogsUpdate(updatedLogs);
      }
    };

    const updateLogsAutoTest = (localWritebackProfiler: WritebackProfiler): void => {
      const { resultRun, resultSum, startTime } = localWritebackProfiler;
      onLogsUpdate([
        createAutoTestLog(
          id,
          OPERATION_TYPE_TO_SHORT[operationType],
          LOG_TO_SHORT[predefinedPayload],
          totalRunWritebackCount,
          startTime!,
          Date.now(),
          resultRun!,
          resultSum!,
        ),
      ]);
    };

    /**
     * @deprecated - Writeback Response v2
     * ToDo: Will be removed soon. Ticket: https://bookmd.atlassian.net/browse/SAM-60070
     * Please use updateResultV2.
     */
    const updateResultV1 = (status: string): any => {
      writebackProfilerV1.autoTest!.finished.push({
        fail: status === OperationResult.SUCCESS ? 0 : 1,
        duration: Date.now() - writebackProfilerV1.startTime!,
      });
      writebackProfilerV1.autoTest!.count++;
      const finished = writebackProfilerV1.autoTest!.finished as {
        fail: number;
        duration: number;
      }[];
      const sumUp: {
        fail: number;
        duration: number;
      } = finished.reduce((sum, finish) => {
        return { fail: sum.fail + finish.fail, duration: sum.duration + finish.duration };
      });
      writebackProfilerV1.resultRun = `Run ${finished.length} writeback in ${
        sumUp.duration / 1000
      } seconds`;
      writebackProfilerV1.resultSum = `Average duration: ${
        sumUp.duration / finished.length / 1000
      } Failed: ${sumUp.fail}`;
    };

    const updateResultV2 = (writebackResponse: WritebackResponse): any => {
      // Since we don't have the status in the response, we will calculate if all fields are failed - it's a failure, otherwise it's a success.
      writebackProfilerV2.autoTest!.finished.push({
        fail: getWritebackResult(writebackResponse) ? 0 : 1,
        duration: Date.now() - writebackProfilerV2.startTime!,
      });
      writebackProfilerV2.autoTest!.count++;
      const finished = writebackProfilerV2.autoTest!.finished as {
        fail: number;
        duration: number;
      }[];
      const sumUp: {
        fail: number;
        duration: number;
      } = finished.reduce((sum, finish) => {
        return { fail: sum.fail + finish.fail, duration: sum.duration + finish.duration };
      });
      writebackProfilerV2.resultRun = `Run ${finished.length} writeback in ${
        sumUp.duration / 1000
      } seconds`;
      writebackProfilerV2.resultSum = `Average duration: ${
        sumUp.duration / finished.length / 1000
      } Failed: ${sumUp.fail}`;
    };

    const isControlsDisabled: boolean = operationType === '';
    let isWritebackAssessment: boolean = false;
    let isWritebackReferralAll: boolean = false;
    let isUIInjection: boolean = false;
    if (operationType) {
      isWritebackAssessment = predefinedPayload === UpdateEncounterType.WRITEBACK_ASSESSMENT;
      isWritebackReferralAll = predefinedPayload === UpdateReferralType.REFERRAL_ALL;
      isUIInjection = operationType === OperationType.UI_INJECTION;
    }

    const handlePublish = (
      e: React.MouseEvent<HTMLButtonElement>,
      version: WritebackVersion,
    ): void => {
      e.stopPropagation();
      setWritebackProfilerV1(new WritebackProfiler());
      setWritebackProfilerV2(new WritebackProfiler());
      let localWritebackProfiler: WritebackProfiler | undefined = undefined;
      if (runWritebackCount > 1) {
        setTotalRunWritebackCount(runWritebackCount);
        autoTesting(version);
      } else {
        if (operationType === OperationType.WRITEBACK) {
          const profiler = {
            ...(version === WritebackVersion.V1 ? writebackProfilerV1 : writebackProfilerV2),
          };
          localWritebackProfiler = {
            ...profiler,
            startTime: Date.now(),
            endTime: null,
            status: null,
            autoTest: null,
          };
          switch (version) {
            case WritebackVersion.V1:
              setWritebackProfilerV1(localWritebackProfiler);
              break;
            case WritebackVersion.V2:
              setWritebackProfilerV2(localWritebackProfiler);
              break;
          }
        }
        runWriteback(localWritebackProfiler, version);
        if (operationId && operationType && predefinedPayload && payload) {
          onWritebackDataChange({
            payload,
            payloadConfig: isWritebackAssessment ? undefined : payloadConfig,
            operationId,
            operationType,
            predefinedPayload,
          });
        }
      }
    };

    const autoTesting = (version: WritebackVersion): void => {
      const profiler = {
        ...(version === WritebackVersion.V1 ? writebackProfilerV1 : writebackProfilerV2),
      };
      const localWritebackProfiler: WritebackProfiler = {
        ...profiler,
        startTime: Date.now(),
        endTime: null,
        status: null,
        autoTest: {
          finished: [],
          fail: 0,
          count: 0,
        },
      };
      switch (version) {
        case WritebackVersion.V1:
          setWritebackProfilerV1(localWritebackProfiler);
          break;
        case WritebackVersion.V2:
          setWritebackProfilerV2(localWritebackProfiler);
          break;
      }
      runWriteback(localWritebackProfiler, version);
    };

    const runWriteback = (
      localWritebackProfiler: WritebackProfiler | undefined,
      version: WritebackVersion,
    ): void => {
      switch (operationType) {
        case OperationType.WRITEBACK:
          setIsLoading(true);
          switch (version) {
            case WritebackVersion.V1:
              sendWritebackData({
                id,
                payload: {
                  writeBack: operationId,
                  data: payload,
                  dataConfig: isWritebackAssessment ? undefined : payloadConfig,
                  operationType,
                  predefinedPayload,
                },
              });
              break;
            case WritebackVersion.V2:
              sendWritebackV2Data({
                id,
                payload: {
                  writeBack: operationId,
                  data: payload,
                  dataConfig: isWritebackAssessment ? undefined : payloadConfig,
                  operationType,
                  predefinedPayload,
                },
              });
              break;
          }
          break;
        case OperationType.UI_INJECTION:
          sendUIInjectionData({
            id,
            payload: {
              uiInjection: operationId,
              data: payload,
            },
          });
          break;
      }
      if (localWritebackProfiler) {
        const updatedLogs: OSLog[] = [
          ...localLogs,
          createLog(
            id,
            OPERATION_TYPE_TO_SHORT[operationType],
            LOG_TO_SHORT[predefinedPayload],
            localWritebackProfiler.startTime!,
          ),
        ];
        onLogsUpdate(updatedLogs);
      }
    };

    const handleOperationTypeChange = ({ target: { value } }: SelectChangeEvent): void => {
      setPayload('');
      setPredefinedPayload('');
      setOperationType(value);
      switch (value) {
        case OperationType.WRITEBACK:
          setOperationId(writebacks[0]);
          break;
        case OperationType.UI_INJECTION:
          setOperationId(uiInjections[0]);
          break;
      }
      setTimeout(() => {
        if (writebacks.length > 0 || uiInjections.length > 0) {
          targetRef.current?.scrollIntoView();
        }
      }, 200);
    };

    const handleWritebackCountChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setRunWritebackCount(validateNumber(e.target.value));
    };

    const handlePayloadJsonEditorChange = (newValue: any): void => {
      setPayload(newValue);
    };

    const handlePayloadConfigJsonEditorChange = (newValue: any): void => {
      setPayloadConfig(newValue);
    };

    const handlePredefinedPayloadChange = ({ target: { value } }: SelectChangeEvent): void => {
      setPredefinedPayload(value);
      if (!value) {
        setPayload('');
        return;
      }
      setUseCase('-1');
      setPayload(
        (getWritebackPayload[operationId] || getUIInjectionPayload[operationId])(
          value,
          expectedWritebackResult,
        ),
      );
    };

    const handleReshuffle = (): void => {
      setUseCase('-1');
      if (expectedWritebackResult !== '-1') {
        const text: string = EXPECTED_WRITEBACK_RESULTS_EXTENDED_OBJECT[expectedWritebackResult];
        if (isWritebackAssessment && isICDCode(text)) {
          setPayload(getWritebackPayload[operationId](predefinedPayload, text));
          return;
        }
      }
      setPayload(
        expectedWritebackResult === '-1'
          ? ''
          : (getWritebackPayload[operationId] || getUIInjectionPayload[operationId])(
              predefinedPayload,
              expectedWritebackResult,
            ),
      );
    };

    const handleWritebackResultChange = ({ target: { value } }: SelectChangeEvent): void => {
      let payloadValue: string = '';
      if (value !== '-1') {
        const text: string = EXPECTED_WRITEBACK_RESULTS_EXTENDED_OBJECT[value];
        payloadValue = isWritebackAssessment ? (isICDCode(text) ? text : value) : value;
      }
      setExpectedWritebackResult(value);
      setUseCase('-1');
      setPayload(
        value === '-1'
          ? ''
          : (getWritebackPayload[operationId] || getUIInjectionPayload[operationId])(
              predefinedPayload,
              payloadValue,
            ),
      );
    };

    const handleUseCaseChange = ({ target: { value } }: SelectChangeEvent): void => {
      setUseCase(value);
      if (!value || expectedWritebackResult === '-1' || !payload) {
        setPayload('');
        return;
      }
      setPayload(generateUseCase(payload, value));
    };

    let selectOptions: never[] = [];
    let selectTitle: string = '';
    switch (operationType) {
      case OperationType.WRITEBACK:
        selectOptions = OPERATIONS_MAP[writebacks[0]];
        selectTitle = 'Select Writeback Type';
        if (isWritebackReferralAll && !payloadConfig) {
          setPayloadConfig(getWritebackPayload.getReferralAllConfig());
        }
        break;
      case OperationType.UI_INJECTION:
        selectOptions = OPERATIONS_MAP[uiInjections[0]];
        selectTitle = 'Select UI Injection Type';
        break;
    }

    const handleWritebackResult = (): void => {
      setTimeout(() => {
        writebackResultRef.current?.scrollIntoView();
      }, 200);
    };

    return (
      <>
        <div className="writeback-controls">
          <Selector
            id={`${eventName}-operation-select`}
            className="operation-select"
            displayEmpty
            value={operationType}
            size="small"
            onChange={handleOperationTypeChange}
          >
            <MenuItem value="">Select Operation Type</MenuItem>
            {Object.values(OperationType).map((operation: OperationType) => (
              <MenuItem key={operation} value={operation}>
                {operation.charAt(0).toUpperCase() + operation.slice(1).replace('-', ' ')}
              </MenuItem>
            ))}
          </Selector>
          {operationType && (
            <Selector
              id={`${eventName}-operation-sub-type`}
              className={`${operationId}-select`}
              displayEmpty
              value={predefinedPayload}
              size="small"
              onChange={handlePredefinedPayloadChange}
              open={openSelectPayload}
              onClick={(e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
                e.stopPropagation();
                setOpenSelectPayload(!openSelectPayload);
              }}
            >
              <MenuItem value="">{selectTitle}</MenuItem>
              {selectOptions?.map((value: string) => (
                <MenuItem
                  id={`${operationId}-${value.replaceAll(' ', '-')}`}
                  value={value}
                  key={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Selector>
          )}
          {isWritebackAssessment && (
            <div className="writeback-result">
              <Selector
                id={`${eventName}-operation-select-result`}
                className={`${operationId}-writeback`}
                displayEmpty
                value={expectedWritebackResult}
                size="small"
                onChange={handleWritebackResultChange}
              >
                {writebackAssessmentsOptions.map(([value, key]) => (
                  <MenuItem value={value} key={value}>
                    {key}
                  </MenuItem>
                ))}
              </Selector>
            </div>
          )}
          {eventType === EventType.LOCKED ? <WritebackWarning /> : null}
          <div className="writeback-actions">
            {isWritebackAssessment && (
              <ButtonIcon
                id={`${eventName}-reshuffle`}
                className="button mini top"
                disabled={isControlsDisabled}
                icon={ICONS_MAP['sync']}
                onClick={handleReshuffle}
              />
            )}
            {!isControlsDisabled && (
              <div className={`run-container${isWritebackAssessment ? '' : ' run-only'}`}>
                <ButtonLoader
                  id={`${eventName}-run-button`}
                  title={`${isUIInjection ? 'Inject' : 'Run V1'}`}
                  className={`${operationId}-button`}
                  containerClassName="margin"
                  onClick={(e) => handlePublish(e, WritebackVersion.V1)}
                  disabled={isControlsDisabled}
                  disableLoader
                />
                {!isUIInjection && (
                  <ButtonLoader
                    id={`${eventName}-run-button-v2`}
                    title="Run V2"
                    className={`${operationId}-button`}
                    onClick={(e) => handlePublish(e, WritebackVersion.V2)}
                    disabled={isControlsDisabled}
                    isLoading={isLoading}
                    loaderSide={LoaderSide.RIGHT}
                  />
                )}
              </div>
            )}
            {isWritebackAssessment && (
              <>
                <Selector
                  id={`${eventName}-operation-useCase`}
                  className={`${operationId}-writeback-useCase`}
                  displayEmpty
                  value={useCase}
                  size="small"
                  onChange={handleUseCaseChange}
                >
                  {useCasesOptions.map(([value, key]) => (
                    <MenuItem value={value} key={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Selector>
                <TextCount
                  id={`${eventName}-runMultipleWriteback`}
                  containerClassName="small"
                  label="Run Count"
                  value={runWritebackCount}
                  onChange={handleWritebackCountChange}
                  disabled={isControlsDisabled}
                />
              </>
            )}
          </div>
        </div>
        {operationType && (
          <>
            {isWritebackReferralAll && (
              <div className="operation-payload-config" ref={targetRef}>
                <div className="payload-title">Config:</div>
                <JsonEditor
                  value={payloadConfig}
                  height={10}
                  onChange={handlePayloadConfigJsonEditorChange}
                />
              </div>
            )}
            <div className="operation-payload" ref={targetRef}>
              {isWritebackReferralAll && <div className="payload-title">Payload:</div>}
              <JsonEditor value={payload} height={20} onChange={handlePayloadJsonEditorChange} />
            </div>
            {writebackProfilerV1.startTime && (
              <div className="writebacks-wrap">
                <div>
                  <p id={`${eventName}-operation-start`}>
                    The writeback started -{' '}
                    {new Date(writebackProfilerV1.startTime).toLocaleTimeString()}
                  </p>
                </div>
                {writebackProfilerV1.endTime && (
                  <div className="writeback-complete-wrap">
                    <p id={`${eventName}-operation-result`}>
                      {`Writeback ${
                        writebackProfilerV1.status === OperationResult.SUCCESS
                          ? 'finished successfully'
                          : OperationResult.FAILED
                      } - ${new Date(writebackProfilerV1.endTime).toLocaleTimeString()}`}
                    </p>
                    <p id={`${eventName}-operation-run-time`}>
                      {`Last run time: ${
                        (writebackProfilerV1.endTime - writebackProfilerV1.startTime) / 1000
                      } seconds`}
                    </p>
                  </div>
                )}
                {writebackProfilerV1.autoTest && (
                  <div className="writeback-complete-wrap">
                    <p id={`${eventName}-operation-result-run`}>{writebackProfilerV1.resultRun}</p>
                    <p id={`${eventName}-operation-result-sum`}>{writebackProfilerV1.resultSum}</p>
                  </div>
                )}
              </div>
            )}
            {writebackProfilerV2.startTime && (
              <div className="writebacks-wrap v2">
                <div>
                  <p id={`${eventName}-operation-start-v2`}>
                    The writeback V2 started -{' '}
                    {new Date(writebackProfilerV2.startTime).toLocaleTimeString()}
                  </p>
                </div>
                {writebackProfilerV2.endTime && (
                  <div className="writeback-complete-wrap v2">
                    <p
                      ref={writebackResultRef}
                      id={`${eventName}-operation-result`}
                    >{`Writeback V2 Result - ${new Date(
                      writebackProfilerV2.endTime,
                    ).toLocaleTimeString()}`}</p>
                    <JsonViewer
                      id={`${eventName}-operation-result-v2`}
                      type={HiddenJsonType.WRITEBACK}
                      value={writebackProfilerV2.writebackResponse}
                      collapsed={20}
                    />
                    <p id={`${eventName}-operation-run-time-v2`}>
                      {`Last run time: ${
                        (writebackProfilerV2.endTime - writebackProfilerV2.startTime) / 1000
                      } seconds`}
                    </p>
                  </div>
                )}
                {writebackProfilerV2.autoTest && (
                  <div className="writeback-complete-wrap v2">
                    <p id={`${eventName}-operation-result-run-v2`}>
                      {writebackProfilerV2.resultRun}
                    </p>
                    <p id={`${eventName}-operation-result-sum-b2`}>
                      {writebackProfilerV2.resultSum}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  },
);

Operation.displayName = 'Operation';
