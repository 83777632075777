import { Standard } from '@getvim/vim-connect';
import { findPatient } from './findPatient';
import { getCurrentEhrUserInformation } from './getCurrentEhrUserInformation';
import { getEncounterData } from './getEncounterData';
import { getEncounterLabResults } from './getEncounterLabResults';
import { getEncounterVitals } from './getEncounterVitals';
import { getPatient } from './getPatient';
import { getPatientEncounters } from './getPatientEncounters';
import { getPatientScheduledAppointments } from './getPatientScheduledAppointments';
import { getProviderDailySummary } from './getProviderDailySummary';
import { navigateToEncounter } from './navigateToEncounter';
import { printEncounter } from './printEncounter';
import { printEncounters } from './printEncounters';
import { searchProviders } from './searchProviders';
import { getProviderAppointments } from './getProviderAppointments';

const {
  GET_PATIENT,
  GET_ENCOUNTER_DATA,
  SEARCH_PROVIDERS,
  GET_CURRENT_EHR_USER_INFORMATION,
  NAVIGATE_TO_ENCOUNTER,
  GET_PROVIDER_DAILY_SUMMARY,
  GET_PATIENT_SCHEDULED_APPOINTMENTS,
  FIND_PATIENT,
  PRINT_ENCOUNTER,
  PRINT_ENCOUNTERS,
  GET_PATIENT_ENCOUNTERS,
  GET_ENCOUNTER_LAB_RESULTS,
  GET_ENCOUNTER_VITALS,
  GET_PROVIDER_APPOINTMENTS,
} = Standard.Actions.ActionNames;

export const defaultActionPayloads: Partial<Record<Standard.Actions.ActionNames, any>> = {
  [GET_PATIENT]: getPatient,
  [GET_ENCOUNTER_DATA]: getEncounterData,
  [SEARCH_PROVIDERS]: searchProviders,
  [GET_CURRENT_EHR_USER_INFORMATION]: getCurrentEhrUserInformation,
  [NAVIGATE_TO_ENCOUNTER]: navigateToEncounter,
  [GET_PROVIDER_DAILY_SUMMARY]: getProviderDailySummary,
  [GET_PATIENT_SCHEDULED_APPOINTMENTS]: getPatientScheduledAppointments,
  [FIND_PATIENT]: findPatient,
  [PRINT_ENCOUNTER]: printEncounter,
  [PRINT_ENCOUNTERS]: printEncounters,
  [GET_PATIENT_ENCOUNTERS]: getPatientEncounters,
  [GET_ENCOUNTER_LAB_RESULTS]: getEncounterLabResults,
  [GET_ENCOUNTER_VITALS]: getEncounterVitals,
  [GET_PROVIDER_APPOINTMENTS]: getProviderAppointments,
};
