import { Standard } from '@getvim/vim-connect';
import { USE_CASES_OBJECT } from '@getvim/vim-connect-app';
import { icdsList } from '../../icds';
import { Diagnosis } from '../../../../../../../packages/adapter/api/src/entities/sub-entities';

export const USE_CASES_OPTIONS: Record<string, string> = { ...USE_CASES_OBJECT };

enum POSITION {
  START = 'start',
  END = 'end',
}

enum CASE_MODE {
  LOWER = 'lower',
  UPPER = 'upper',
}

enum PROPERTY {
  CODE = 'code',
  DESCRIPTION = 'description',
}

function addSpecialCharacters(description: string): string {
  const specialChars: string[] = ['*', '%', '$', '^', '#', '&', '!', '=', '_', '+'];
  let newDescription: string = '';
  for (const char of description) {
    newDescription += char;
    if (Math.random() < 0.5) {
      newDescription += specialChars[Math.floor(Math.random() * specialChars.length)];
    }
  }
  return newDescription;
}

function addRandomSpaces(description: string, position: POSITION): string {
  const numSpaces: number = Math.floor(Math.random() * 10) + 1;
  if (position === POSITION.START) {
    return `${' '.repeat(numSpaces)}${description}`;
  } else if (position === POSITION.END) {
    return `${description}${' '.repeat(numSpaces)}`;
  }
  return description;
}

function randomUpperLower(description: string): string {
  let result: string = '';
  for (const char of description) {
    const rand: number = Math.random();
    result += rand < 0.5 ? char.toUpperCase() : char.toLowerCase();
  }
  return result;
}

function cutWords(description: string, position: POSITION): string {
  const words: string[] = description.trim().split(/\s+/);
  const numWordsToRemove: number = Math.max(1, Math.floor(Math.random() * words.length));
  if (position === POSITION.START) {
    const startIndex: number = numWordsToRemove;
    return words.slice(startIndex).join(' ');
  } else if (position === POSITION.END) {
    const endIndex: number = words.length - numWordsToRemove;
    return words.slice(0, endIndex).join(' ');
  }
  return description;
}

function getRandomWords() {
  const words: string[] = ['pass', 'bla', 'bli', 'only', 'win', 'vim', 'we-crap', 'chuwei'];
  const numWordsToAdd: number = Math.max(1, Math.floor(Math.random() * 5) + 1);
  const selectedWords: string[] = [];
  for (let i = 0; i < numWordsToAdd; i++) {
    const randomIndex: number = Math.floor(Math.random() * words.length);
    selectedWords.push(words[randomIndex]);
  }
  return selectedWords.join(' ');
}

function generateRandomCode(): string {
  const codes: string[] = Array.from(icdsList.values());
  const randomIndex: number = Math.floor(Math.random() * codes.length);
  return codes[randomIndex];
}

function generateRandomDescription(): string {
  const descriptions: string[] = Array.from(icdsList.keys());
  const randomIndex: number = Math.floor(Math.random() * descriptions.length);
  return descriptions[randomIndex];
}

function addDescription(
  payload: Standard.WriteBacks.UpdateEncounter,
  position: POSITION,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    const originalDescription: string = firstDiagnosis.description || '';
    const addedWords: string = getRandomWords();
    let modifiedDescription: string;
    if (position === POSITION.START) {
      modifiedDescription = `${addedWords} ${originalDescription.trim()}`;
    } else if (position === POSITION.END) {
      modifiedDescription = `${originalDescription.trim()} ${addedWords}`;
    } else {
      throw new Error('Invalid position provided');
    }
    payload.assessments.diagnosisCodes[0] = {
      ...firstDiagnosis,
      description: modifiedDescription,
    };
  }
  return payload;
}

function cutDescription(
  payload: Standard.WriteBacks.UpdateEncounter,
  position: POSITION,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    if (firstDiagnosis.description) {
      const modifiedDescription: string = cutWords(firstDiagnosis.description, position);
      payload.assessments.diagnosisCodes[0] = {
        ...firstDiagnosis,
        description: modifiedDescription,
      };
    }
  }
  return payload;
}

function modifyDescriptionWithSpaces(
  payload: Standard.WriteBacks.UpdateEncounter,
  position: POSITION,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    if (firstDiagnosis.description) {
      const modifiedDescription: string = addRandomSpaces(firstDiagnosis.description, position);
      payload.assessments.diagnosisCodes[0] = {
        ...firstDiagnosis,
        description: modifiedDescription,
      };
    }
  }
  return payload;
}

function modifyDescriptionCase(
  payload: Standard.WriteBacks.UpdateEncounter,
  mode: CASE_MODE,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    if (firstDiagnosis.description) {
      let modifiedDescription: string;
      switch (mode) {
        case CASE_MODE.LOWER:
          modifiedDescription = firstDiagnosis.description.toLowerCase();
          break;
        case CASE_MODE.UPPER:
          modifiedDescription = firstDiagnosis.description.toUpperCase();
          break;
        default:
          throw new Error('Invalid case mode provided');
      }
      payload.assessments.diagnosisCodes[0] = {
        ...firstDiagnosis,
        description: modifiedDescription,
      };
    }
  }
  return payload;
}

function modifyDiagnosisProperty(
  payload: Standard.WriteBacks.UpdateEncounter,
  property: PROPERTY,

  valueGenerator: () => string,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis = payload.assessments.diagnosisCodes[0];
    const newValue: string = valueGenerator();
    payload.assessments.diagnosisCodes[0] = {
      ...firstDiagnosis,
      [property]: newValue,
    };
  }
  return payload;
}

function duplicateDiagnosisProperty(
  payload: Standard.WriteBacks.UpdateEncounter,
  property: PROPERTY,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    let duplicatedDiagnosis: any;
    switch (property) {
      case PROPERTY.CODE:
        duplicatedDiagnosis = { code: firstDiagnosis.code, description: '' };
        break;
      case PROPERTY.DESCRIPTION:
        duplicatedDiagnosis = { code: '', description: firstDiagnosis.description };
        break;
      default:
        throw new Error('Invalid property provided');
    }
    payload.assessments.diagnosisCodes = [
      ...payload.assessments.diagnosisCodes,
      duplicatedDiagnosis,
    ];
  }
  return payload;
}

function removeDiagnosisProperty(
  payload: Standard.WriteBacks.UpdateEncounter,
  property: PROPERTY,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis = payload.assessments.diagnosisCodes[0];
    payload.assessments.diagnosisCodes[0] = {
      ...firstDiagnosis,
      [property]: '',
    };
  }
  return payload;
}

function duplicateFirst(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    payload.assessments.diagnosisCodes.splice(1, 0, payload.assessments.diagnosisCodes[0]);
  }
  return payload;
}

function duplicateLast(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const lastDiagnosis =
      payload.assessments.diagnosisCodes[payload.assessments.diagnosisCodes.length - 1];
    payload.assessments.diagnosisCodes = [...payload.assessments.diagnosisCodes, lastDiagnosis];
  }
  return payload;
}

function duplicateCode(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return duplicateDiagnosisProperty(payload, PROPERTY.CODE);
}

function duplicateDescription(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return duplicateDiagnosisProperty(payload, PROPERTY.DESCRIPTION);
}

function specialCharacters(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    if (firstDiagnosis.description) {
      const modifiedDescription: string = addSpecialCharacters(firstDiagnosis.description);
      payload.assessments.diagnosisCodes[0] = {
        ...firstDiagnosis,
        description: modifiedDescription,
      };
    }
  }
  return payload;
}

function spacesStart(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDescriptionWithSpaces(payload, POSITION.START);
}

function spacesEnd(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDescriptionWithSpaces(payload, POSITION.END);
}

function lowerCase(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDescriptionCase(payload, CASE_MODE.LOWER);
}

function upperCase(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDescriptionCase(payload, CASE_MODE.UPPER);
}

function upperLowerCase(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 0) {
    const firstDiagnosis: Diagnosis = payload.assessments.diagnosisCodes[0];
    if (firstDiagnosis.description) {
      const modifiedDescription: string = randomUpperLower(firstDiagnosis.description);
      payload.assessments.diagnosisCodes[0] = {
        ...firstDiagnosis,
        description: modifiedDescription,
      };
    }
  }
  return payload;
}

function cutDescriptionStart(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return cutDescription(payload, POSITION.START);
}

function cutDescriptionEnd(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return cutDescription(payload, POSITION.END);
}

function addDescriptionStart(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return addDescription(payload, POSITION.START);
}

function addDescriptionEnd(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return addDescription(payload, POSITION.END);
}

function changeCode(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDiagnosisProperty(payload, PROPERTY.CODE, generateRandomCode);
}

function changeDescription(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return modifyDiagnosisProperty(payload, PROPERTY.DESCRIPTION, generateRandomDescription);
}

function removeCode(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return removeDiagnosisProperty(payload, PROPERTY.CODE);
}

function removeDescription(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  return removeDiagnosisProperty(payload, PROPERTY.DESCRIPTION);
}

function reorder(
  payload: Standard.WriteBacks.UpdateEncounter,
): Standard.WriteBacks.UpdateEncounter {
  if (payload.assessments?.diagnosisCodes && payload.assessments.diagnosisCodes.length > 1) {
    for (let i = payload.assessments.diagnosisCodes.length - 1; i > 0; i--) {
      const j: number = Math.floor(Math.random() * (i + 1));
      [payload.assessments.diagnosisCodes[i], payload.assessments.diagnosisCodes[j]] = [
        payload.assessments.diagnosisCodes[j],
        payload.assessments.diagnosisCodes[i],
      ];
    }
  }
  return payload;
}

const useCaseHandlers = {
  duplicateFirst,
  duplicateLast,
  duplicateCode,
  duplicateDescription,
  specialCharacters,
  spacesStart,
  spacesEnd,
  lowerCase,
  upperCase,
  upperLowerCase,
  cutDescriptionStart,
  cutDescriptionEnd,
  addDescriptionStart,
  addDescriptionEnd,
  changeCode,
  changeDescription,
  removeCode,
  removeDescription,
  reorder,
};

export function generateUseCase(payload: string, useCase: string): string {
  const handler: any = useCaseHandlers[useCase];
  return (handler ? handler(payload) : payload) as string;
}
